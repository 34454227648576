import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'

export const modalCallbacks = {
  continueButtonHandler: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Clicked',
        eventCategory: 'email re-engagement Modal',
        eventLabel: 'Continue Modal Button',
        eventLabelValue: undefined,
      },
    })
  },
  closeButtonHandler: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Clicked',
        eventCategory: 'email re-engagement Modal',
        eventLabel: 'Close Modal Button',
        eventLabelValue: undefined,
      },
    })
  },
  showModal: () => {
    return pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Shown',
        eventCategory: 'reEngagement Tooltip Modal',
        eventLabel: 'reEngagement Modal Message',
        eventLabelValue: undefined,
      },
    })
  },
}
