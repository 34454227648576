import * as React from 'react'

import {
  i18n,
  useHeaderHeight,
  useSiteConfig,
} from '@thg-commerce/enterprise-core'
import {
  EnterpriseThemeInterface,
  withTheme,
} from '@thg-commerce/enterprise-theme'
import { Modal } from '@thg-commerce/gravity-elements'
import { PictureProps } from '@thg-commerce/gravity-system'

import { modalCallbacks } from './dataTracking'
import { EmailEngagementModalContent } from './EmailEngagementModalContent'

export enum FormStatus {
  UNSUBMITTED = 'UNSUBMITTED',
  REJECTED = 'REJECTED',
  FAILURE = 'FAILURE',
  SUCCESSFUL = 'SUCCESSFUL',
}

export interface EmailEngagementModalProps {
  picture: PictureProps
  open: boolean
  status: FormStatus
  modalTitle: string
  bulletText: string[]
  statusHandler: (status: FormStatus) => void
  closeHandler: () => void
}

export const EmailEngagementModal = withTheme(
  (props: EmailEngagementModalProps & { theme: EnterpriseThemeInterface }) => {
    const headerHeight = useHeaderHeight()
    const { allowAlternateTermsAndConditions } = useSiteConfig()

    React.useEffect(() => {
      if (props.open) {
        modalCallbacks.showModal()
      }
    }, [])

    const i18nText = {
      closeAriaLabel: i18n('general.modal.close.button.arialabel'),
      closeLabel: i18n('general.modal.close.button.arialabel'),
      inputLabel: i18n('form.fieldname.email'),
      submitButton: i18n('general.signup.text'),
      successTitle: i18n('emailengagement.success.title'),
      successText: i18n('emailengagement.success.text'),
      continueButton: i18n('general.continueshopping.label'),
      tryAgainButton: i18n('emailengagement.retry.button.text'),
      privacyPolicyText: allowAlternateTermsAndConditions
        ? i18n(
            'account.newaccount.createaccountform.alternatetermsandconditions.text.pt4',
          )
        : i18n(
            'account.newaccount.createaccountform.termsandconditions.text.pt4',
          ),
      privacyPolicyLink: allowAlternateTermsAndConditions
        ? i18n(
            'account.newaccount.createaccountform.alternatetermsandconditions.link.privacy',
          )
        : i18n(
            'account.newaccount.createaccountform.termsandconditions.link.privacy',
          ),
      errorTitle: i18n('verificationtokenerror.invalid.title.text'),
      errorText: i18n('emailengagement.retry.text'),
    }

    return (
      <Modal
        open={props.open}
        gridColSpan={[12, 12, 8, 6]}
        gridRowSpan={[12, 10, 10, 12]}
        i18nText={i18nText}
        contentPadding="0 0 0"
        headerOffset={headerHeight}
        verticalAlignment="center"
        onClose={() => {
          if (props.status === FormStatus.UNSUBMITTED) {
            props.statusHandler(FormStatus.REJECTED)
          }
          props.closeHandler()
          modalCallbacks.closeButtonHandler()
        }}
        showHeader={[true, false, false, false]}
        clickOutsideDeactivates={true}
        shouldRemoveScrollLock={false}
        data-testid="engagement-modal"
      >
        <EmailEngagementModalContent {...props} i18nText={i18nText} />
      </Modal>
    )
  },
)
