import { SIGNUP_FOR_MARKETING_MUTATION } from './EmailEngagementModalContent'
export const mockQuery = [
  {
    request: {
      query: SIGNUP_FOR_MARKETING_MUTATION,
      variables: {
        input: {
          type: 'EMAIL',
          contactDetails: 'valid@email.com',
          auditData: {
            messageShown:
              'Exclusive Offers\r\nBest fitness tips and guides\r\nFirst look at new product launches',
            formIdentifier: 'MARKETING_MATERIAL_SIGN_UP',
            formLocation: 'EmailEngagementModal',
          },
        },
      },
    },
    result: {
      data: {
        signUpForMarketing: {
          data: null,
        },
      },
    },
  },
  {
    request: {
      query: SIGNUP_FOR_MARKETING_MUTATION,
      variables: {
        input: {
          type: 'EMAIL',
          contactDetails: 'valid@email.com',
          auditData: {
            messageShown:
              'Exclusive Offers\r\nBest fitness tips and guides\r\nFirst look at new product launches',
            formIdentifier: 'MARKETING_MATERIAL_SIGN_UP',
            formLocation: 'EmailEngagementModal',
          },
        },
      },
    },
    result: {
      data: {
        signUpForMarketing: {
          data: null,
        },
      },
    },
  },
  {
    request: {
      query: SIGNUP_FOR_MARKETING_MUTATION,
      variables: {
        input: {
          type: 'EMAIL',
          contactDetails: 'signUpForMarketingError@ely2.com',
          auditData: {
            messageShown:
              'Exclusive Offers\r\nBest fitness tips and guides\r\nFirst look at new product launches',
            formIdentifier: 'MARKETING_MATERIAL_SIGN_UP',
            formLocation: 'EmailEngagementModal',
          },
        },
      },
    },
    result: {
      data: {
        signUpForMarketing: {
          data: null,
          errors: [
            {
              message: 'MARKETING_MATERIAL_SIGN_UP',
            },
          ],
        },
      },
    },
  },
]
